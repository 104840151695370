<template>
	<div class="calendar-settings-host-menu">
		<div class="hosts">
			<CollapseCard 
			v-for="host in hosts" 
			:key="`host_${host.id}`" 
			:title="host.name">

				<template v-slot:tools>
					<div class="card-footer-item">Edit</div>
					<div class="card-footer-item">Delete</div>
				</template>				

			</CollapseCard>
		</div>

		<div v-if="!formIsOpen">
			<b-button v-if="!formIsOpen" type="is-info" @click="toggleForm">Add host</b-button>
		</div>

		<div class="add-host-form" v-if="formIsOpen">
			<b-field label="Email" type="email"
				horizontal>
				<b-input type="email" maxlength="30"></b-input>
			</b-field>
			<b-field label="Description" horizontal>
				<b-input></b-input>
			</b-field>

			<div class="buttons">
				<b-button type="is-info">Save</b-button>
				<b-button type="is-info is-light" @click="toggleForm">Cancel</b-button>
			</div>

		</div>
	</div>
</template>

<script>
import { Button } from 'buefy'
import CollapseCard from '../../inputs/CollapseCard.vue';
 
export default {
	name: 'HostMenu',
	components: {
		Button,
		CollapseCard,
	},
	computed: {
		hosts(){
			let hosts = this.$store.getters.calendarHosts
			if (!hosts || hosts.length == 0) 
				return []
			let result = []
			hosts.forEach(host => {
				result.push({...host, isOpen: false})
			})
			return result
		}
	},

	// created() {
	// 	if (this.$store.getters.calendarHosts.length == 0){
	// 		this.$store.dispatch('setup-slot-templates')
	// 	}
	// },

	data() {
		return {
			openedHostId: null,	
			formIsOpen: false,
		};
	},

	mounted() {
		
	},

	methods: {
		openHost(hostId){
			if (this.openedHostId == hostId)
				this.openedHostId = null
			else 
				this.openedHostId = hostId
		},
		toggleForm(){
			this.formIsOpen = !this.formIsOpen
		}
	},
};
</script>

<style scoped>
.calendar-settings-host-menu{
	margin-top: 32px;
    padding: 30px 10px 0 10px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.hosts-item__header{
	padding: 7px 6px;
    display: grid;
    grid-template-columns: calc(100% - 23px - 16px) 23px;
    grid-column-gap: 16px;
	background: #a8cdf6;
}

.hosts-item__details{
	border: 1px solid #a8cdf6;
	height: 36px;
}


.hosts-item__header > * {
	display: flex;
	align-items: center;
}

.hosts-item__details {
	display: flex;
	flex-direction: row;
	gap: 16px;
	padding: 10px;
}

.hosts-item-details__avatar{
	height: 30px;
	width: 30px;
	border: 1px solid black;
	border-radius: 15px;
}

</style>