<template>
	<div class="collapse">
		<!-- <div class="collapse__header" v-bind:style="{
			background: backgroundColor,
		}">
			<div class="collapse-header__title">{{title}}</div>
			<div class="collapse-header__icons">
				<div class="collapse-header__score" v-if="score && score < 5">
					<div class="score__item" v-for="sc in score" :key="`score_item_${sc}`"></div>
				</div>
				<div class="collapse-header__score" v-if="score && score >= 5">
					<div class="score__count">{{score}}</div>
					<div class="score__item"></div>
				</div>
				<div class="collapse-header__toggle-button" @click="toggleOpen" v-bind:class="{
					opened: isOpen,
				}">
					<span class="iconify"
					data-icon="material-symbols:keyboard-arrow-down-rounded" 
					data-width="23" data-height="23">
					</span>
				</div>
			</div>
		</div>
		<transition name="content">
			<div class="collapse__collapse" v-if="isOpen"
			v-bind:class="{
				bordered: bordered,
			}"
			v-bind:style="{
				'background': bordered? 'white' : addOpacityToColor(backgroundColor, 0.5),
				'border-color': addOpacityToColor(backgroundColor, 0.5),
			}">
				<div class="collapse__content" v-if="$slots.default">
					<slot></slot>
				</div>
				<div class="collapse__tool-bar" v-if="$slots.tools">
					<slot name="tools"></slot>
				</div>
			</div>
		</transition> -->
		<!-- <div class="card">
			<header class="card-header">
				<p class="card-header-title">
					{{title}}
				</p>
				<button class="card-header-icon" aria-label="more options">
				<div class="icon" @click="toggleOpen">
					<img v-if="isOpen" src="@/assets/keyboard-arrow-up-rounded.svg" height="24" width="24">
					<img v-else src="@/assets/keyboard-arrow-down-rounded.svg" height="24" width="24">
				</div>
				</button>
			</header>
			<span v-if="isOpen">
				<div class="card-content" v-if="$slots.default">
					<slot></slot>
				</div>
				<footer class="card-footer" v-if="$slots.tools">
					<slot name="tools"></slot>
				</footer>
			</span>
		</div> -->

		<b-collapse
            class="card"
            animation="slide"
            :open="isOpen"
            @open="isOpen = true"
            :aria-id="'contentIdForA11y5-' + _uid">
            <template #trigger="props">
                <div class="card-header"
                    role="button"
                    :aria-controls="'contentIdForA11y5-' + _uid"
                    :aria-expanded="props.open">
					<div class="card-header-tag" v-if="score">
						<b-tag type="is-info" >{{ score }}</b-tag>
					</div>

                    <p class="card-header-title">
                        {{ title }}
                    </p>

                    <a class="card-header-icon">
						<img v-if="props.open" src="@/assets/keyboard-arrow-up-rounded.svg" height="24" width="24">
						<img v-else src="@/assets/keyboard-arrow-down-rounded.svg" height="24" width="24">
                    </a>
                </div>
            </template>
			<div class="card-content" v-if="$slots.default">
				<slot></slot>
			</div>
			<footer class="card-footer" v-if="$slots.tools">
				<slot name="tools"></slot>
			</footer>
        </b-collapse>
	</div>
</template>

<script>
import {addOpacityToColor} from '../../scripts/color'
import {Collapse, Icon, Tag} from 'buefy'

export default {
	name: 'CollapseCard',
	components: {
		Collapse,
		Icon,
		Tag,
	},
	props: {
		backgroundColor: {
			type: String,
			default: "#d4e3fc"
		},
		title: {
			type: String,
			required: true,
		},
		score: {
			type: Number,
			default: 0,
		},
		scoreIcon: {
			type: String,
			default: "",
		},
		bordered: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			isOpen:false,
		};
	},

	methods: {
		addOpacityToColor: addOpacityToColor,
		toggleOpen(){
			console.log(this);
			this.isOpen = !this.isOpen
		}
	},
};
</script>

<style scoped>
.collapse{
	animation: fade .3s;
}

.collapse__header{
	padding: 7px 6px 5px 6px;
	display: flex;
	flex-direction: row;
	gap: 16px;
}


.collapse-header__title{
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.collapse-header__icons{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.collapse-header__toggle-button{
	display: flex;
	align-items: center;
}

.collapse-header__toggle-button.opened{
	transform: rotate(180deg);
}

.collapse__collapse{
	max-height: 144px;
}
.collapse__collapse.bordered{
	border: 2px solid;
}

.content-enter-active{
	animation: content-anim .3s;
}

.content-leave-active{
	animation: content-anim .3s reverse ;
}

@keyframes content-anim {
	from {
		max-height: 0;
	}
	to {
		max-height: 144px;
	}	
}

.collapse-header__score{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.score__item{
	height: 19px;
	width: 19px;
	border-radius: 12px;
	background: #0075ff;
}

.score__count{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 19px;
}

.collapse__content{
	height: 100%;
	padding: 10px;
}

.collapse__tool-bar{
	height: 32px;
}

.card-header-tag{
	display: flex;
	align-items: center;
	padding-left: 16px;	
}

.card-header-title{
	font-weight: 500;
}
</style>